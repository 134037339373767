import React from 'react';
import Access from './Access';
import Pictures from '../profile/Pictures';

const Home = ( props ) => {
	return (
		<React.Fragment>
			<Pictures YouProfile={props.YouProfile} edit="off" />
			<Access />
		</React.Fragment>
	);
}


export default Home;
