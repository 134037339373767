import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { maskCpf } from '../../utils/maskCpf';
import MaskInput from 'react-maskinput';
import swal from 'sweetalert';


const LoginFormRegister = () => {

	const [name, setName] = useState('');
	const [mail, setMail] = useState('');
	const [mail2, setMail2] = useState('');
	const [documento, setDocument] = useState('');
	const [birth, setBirth] = useState(new Date().toISOString().split('T')[0]);
	const [sex, setSex] = useState('');
	const [acepted, setAcepted] = useState('');
	const [alertMessage, setAlert] = useState('');


	async function handleSubmit(e) {
		e.preventDefault();

		if (name.length < 10) {
			setAlert('Nome Inválido')
			return false;
		}

		if (!mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
			setAlert('E-mail Inválido')
			return false;
		}

		if (mail !== mail2) {
			setAlert('Os E-mails preenchidos não coincidem')
			return false;
		}

		let doc = documento.replace(/[^\d]+/g, '');

		if (!checkCPF(doc)) {
			setAlert('CPF inválido')
			return false;
		}

		if (!acepted || acepted === '') {
			setAlert('Para continuar é necessário aceitar os Termos e Condições')
			return false;
		}

		swal({
			title: "Deseja continuar?",
			text: "Nome: " + name + "\nCPF: " + doc + "\n E-mail: " + mail,
			icon: "warning",
			buttons: true,
			dangerMode: false,
		})
			.then(async (willConfirm) => {
				if (willConfirm) {

					window.preloader(true);

					const response = await api.post('/sso/user/register', {
						name,
						mail,
						document: doc,
						birth: '1980-01-01',
						sex,
						acepted
					},
						{ headers: { 'Authorization': 'Bearer ' + window.key } });

					if ('undefined' === typeof response.data) {
						setAlert('Houve uma falha. Contate o suporte.')
						return false;
					}
					if ('undefined' === typeof response.data.status) {
						setAlert('Houve uma falha. Contate o suporte.')
						return false;
					}

					if (response.data.status === 'success') {
						localStorage.setItem('@YouMail', btoa(mail));
						localStorage.setItem('@YouRegisterCode', response.data.data.hash);

						swal("Cadastro efetuado com sucesso!", {
							icon: "success",
						});
						window.location.href = '/register';
					} else {
						setAlert(response.data.message)
					}

					window.preloader(false);
				} else {
					swal("Cadastro cancelado!");
				}
			});

		return false;

	}

	function ativacaoRadio() {
		if (document.getElementById('agree').checked == true) {
			document.getElementById('nomeEmpresa').classList.add("form-visible");
		}
		else {
			document.getElementById('nomeEmpresa').classList.remove("form-visible");
		}
	}


	function checkCPF(cpf) {
		cpf = cpf.replace(/[^\d]+/g, '');
		if (cpf === '') return false;
		if (cpf.length !== 11 ||
			cpf === "00000000000" ||
			cpf === "11111111111" ||
			cpf === "22222222222" ||
			cpf === "33333333333" ||
			cpf === "44444444444" ||
			cpf === "55555555555" ||
			cpf === "66666666666" ||
			cpf === "77777777777" ||
			cpf === "88888888888" ||
			cpf === "99999999999")
			return false;

		let add = 0;
		for (let i = 0; i < 9; i++)
			add += parseInt(cpf.charAt(i)) * (10 - i);
		let rev = 11 - (add % 11);
		if (rev === 10 || rev === 11)
			rev = 0;
		if (rev !== parseInt(cpf.charAt(9)))
			return false;

		add = 0;
		for (let i = 0; i < 10; i++)
			add += parseInt(cpf.charAt(i)) * (11 - i);
		rev = 11 - (add % 11);
		if (rev === 10 || rev === 11)
			rev = 0;
		if (rev !== parseInt(cpf.charAt(10)))
			return false;
		return true;
	}

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-12">
					<h4 className="text-center white pt-3 pb-2 mb-1">Bem-vindo <b className="strong"><span className="green">YOU</span>SER!</b></h4>
					<p className="text-center pb-2">Preencha os campos obrigatórios para se cadastrar</p>
				</div>
			</div>
			<form className="content register" onSubmit={handleSubmit}>
				<div className="row">
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Nome Completo</label>
							<input className="form-control" placeholder="" type="text" value={name} onChange={e => setName(e.target.value)} />
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">E-mail</label>
							<input className="form-control" placeholder="" type="email" value={mail} onChange={e => setMail(e.target.value)} />
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Confirmar E-mail</label>
							<input className="form-control" placeholder="" type="email" value={mail2} onChange={e => setMail2(e.target.value)} />
						</div>
					</div>
					<div className="col col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 pr-xl-1 pr-lg-1 pr-md-1">
						<div className="form-group label-floating">
							<label className="control-label">CPF</label>
							<MaskInput className="form-control" alwaysShowMask maskChar="_" mask="000.000.000-00" size={14} value={documento} onChange={e => setDocument(e.target.value)} />
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<p className="alert">{alertMessage}</p>
					</div>
					<div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-7">

						<label>Você trabalha em Contact Center?</label>
					</div>
					<div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-5">
						<div className="radio">
							<label>
								<input type="radio" name="optionsRadios" onClick={ativacaoRadio} defaultValue="option1" id="agree" /><span className="circle" /><span className="check" />
							Sim
						</label>
							<label>
								<input type="radio" name="optionsRadios" onClick={ativacaoRadio} defaultValue="option2" defaultChecked /><span className="circle" /><span className="check" />
							Não
						</label>
						</div>
					</div>
					<div className="col" id="nomeEmpresa">
						<div className="form-group label-floating">
							<label className="control-label">Qual o nome da empresa?</label>
							<input className="form-control" type="text" />
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
						<div className="remember">
							<div className="checkbox">
								<label>
									<input name="optionsCheckboxes" type="checkbox" value={acepted} onChange={e => setAcepted(e.target.checked)} />
									Eu aceito os <a href="/terms" className="link-login" target="_blank">termos e condições</a> deste site.
								</label>
							</div>
						</div>
						<a href="/#" className="btn btn-primary btn-lg full-width" onClick={handleSubmit}>Registrar!</a>
					</div>
				</div>
			</form>
		</React.Fragment >
	);
}


export default LoginFormRegister;
