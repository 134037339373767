import React from 'react';
import { Redirect } from 'react-router-dom'
import api from '../utils/api';
import cookie from '../utils/cookie'
import checkSession from '../utils/checkSession'

import Header from '../components/login/Header';
import LoginFormRegister from '../components/login/FormRegister';
import LoginFormAccess from '../components/login/FormAccess';


const Login = (props) => {

	const handleLoginUser = async function (data) {
		window.preloader(true);
		const response = await api.post('/sso/auth', data, { headers: { 'Authorization': 'Bearer ' + window.key } });
		window.preloader(false);
		setInfo(response.data)
	}

	function mudarFundo(){
		var bodyClass = document.querySelector('body');
		var bgLogin= document.getElementById('bg-login');
		bodyClass.classList.add("login1")
		bodyClass.classList.remove("login2");
		bgLogin.style.backgroundImage = "url('img/bg-login.jpg')";


	}

	function mudarFundo2(){
		var bodyClass = document.querySelector('body');
		var bgLogin= document.getElementById('bg-login');
		bodyClass.classList.add("login2")
		bodyClass.classList.remove("login1")
		bgLogin.style.backgroundImage = "url('img/bg-register.jpg')";


	}

	const addAlert = function (message) {
		const alert = document.querySelector('#bg-login .alert.access');
		alert.classList.remove("hide");

		if (message === '') {
			alert.classList.add("hide");
			return false;
		}
		alert.innerHTML = message;
	}


	const setInfo = async function (data) {
		if ('undefined' === typeof data.status) {
			data = {
				status: 'error',
				message: 'Falha, contate o suporte'
			}
		}

		if (data.status === 'error') {
			addAlert(data.message);
			return false;
		}

		if ('undefined' === typeof data.data.oauth) {
			addAlert('Falha, contate o suporte');
			return false;
		}

		cookie.set('YouToken', data.data.oauth);
		cookie.set('YouSoftphone', data.data.oauth);

		if (!checkSession()) {
			data = {
				status: 'error',
				message: 'Falha, contate o suporte'
			}
			addAlert('Falha, contate o suporte');
			return false;
		}

		const response = await api.get('/sso/user', { headers: { 'Authorization': 'Bearer ' + data.data.oauth } });
		if (!setInfoUser(response.data)) {
			data = {
				status: 'error',
				message: 'Falha, contate o suporte'
			}
			addAlert('Falha, contate o suporte');
			return false;
		}

		window.location.href = '/home'
	}


	const setInfoUser = function (data) {
		if ('undefined' === typeof data.status) {
			data = {
				status: 'error',
				message: 'Falha, contate o suporte'
			}
		}

		if (data.status === 'error') {
			return false;
		}

		if ('undefined' === typeof data.data.user) {
			return false;
		}

		cookie.set('YouProfile', JSON.stringify(data.data.user));

		if (!checkSession('YouProfile')) {
			return false;
		}

		return true;
	}


	if (checkSession('YouToken')) {
		return <Redirect to="/home" />
	} else {
		return (
			<React.Fragment>
				<Header />
				<div className="container">
					<div id="bg-login" className="row display-flex height-login">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" id="content-left-bg">
							<div className="pl-4 pt-4 pb-4">
								<p className="font-18 white">Soluções Callflex</p>
								<p className="mb-2"><span className="font-52 white">aprenda muito</span><br />
								<span className="font-61 green">sem sair de casa</span></p>
								<ul className="white font-18 list-bg-login mb-4">
									<li>Televendas: entender para atender</li>
									<li>Dialog: melhor performance</li>
									<li>Comunicação + Atendimento ao Cliente</li>
								</ul>
								<p className="pt-4"><img src="img/callflex_youniversity.svg" height={30} alt="Callflex" /> </p>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" id="content-left-bg1">
							<div className="pl-4 pt-4 pb-4">
								<p className="font-18 white">Soluções Callflex</p>
								<p className="mb-4"><span className="font-52 white">tenha o</span><br />
								<span className="font-61 green">conhecimento</span><br />
								<span className="font-52 white">em suas mãos</span></p>
								<p className="pt-4"><img src="img/callflex_youniversity.svg" height={30} alt="Callflex" /> </p>								
							</div>
						</div>
						<div className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 height-login">
							<div className="registration-login-form">
								<ul className="nav nav-tabs" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" data-toggle="tab" onClick={mudarFundo} href="#profile" role="tab">
											<img src="/img/login/user.svg" alt="CallflexYou" />
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-toggle="tab" onClick={mudarFundo2} href="#home" role="tab">
											<img src="/img/login/user_add.svg" alt="CallflexYou" />
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane" id="home" role="tabpanel" data-mh="log-tab">
										<LoginFormRegister />
									</div>
									<div className="tab-pane active" id="profile" role="tabpanel" data-mh="log-tab">
										<LoginFormAccess onSubmit={handleLoginUser} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer footer-full-width" id="footer">
					<div className="container">
						<div className="row">
							<div className="col col-lg-12 col-md-12 col-sm-12 col-12" >
								<div className="sub-footer-copyright">
								© Copyright -<a href="/">Callflex Sistemas Digitais</a> All Rights Reserved 2020
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}


export default Login;
