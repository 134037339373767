import { Cookies } from 'react-cookie';

const checkCookie = ( name ) => {
    const me = new Cookies();
    const You = me.get( name ) || '';
    if( You === '' ) return false;
    return You;
}

const handleUserData = function ( name = 'YouToken' ){
    const YouToken = checkCookie( name );
    if( !YouToken ) return false;
    return YouToken;
}

export default handleUserData;
