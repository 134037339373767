import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import checkSession from '../utils/checkSession'

import Login from './Login.jsx';
import Register from './Register.jsx';
import RegisterProduct from './RegisterProduct.jsx';
import Home from './Home.jsx';
import Logout from './Logout.jsx';
import Profile from './Profile.jsx';
import ToWork from './ToWork.jsx';
import ProfileAddress from './ProfileAddress.jsx';
import ProfileComplementary from './ProfileComplementary.jsx';
import ProfileSocialMedia from './ProfileSocialMedia.jsx';
import ProfileProfessional from './ProfileProfessional.jsx';


import Terms from './Terms.jsx';
import Policy from './Policy.jsx';
import Contact from './Contact.jsx';
import Faq from './Faq.jsx';


const App = ( props ) => {

	if( !checkSession() && 
		window.location.pathname !== "/" && 
		window.location.pathname.indexOf("/register/") === -1 && 
		window.location.pathname !== "/register" && 
		window.location.pathname !== "/terms" && 
		window.location.pathname !== "/privacy-policy" && 
		window.location.pathname !== "/contact" && 
		window.location.pathname !== "/faq" ) {
		window.location.href = '/';
	}else{
		return (
			<BrowserRouter>
				<Switch>
					<Route path="/" exact={true} component={Login} />
					<Route path="/register/:key" exact={true} component={RegisterProduct} />
					<Route path="/register" exact={true} component={Register} />
					<Route path="/logout" component={Logout} />
					<Route path="/home" component={Home} />

					<Route path="/profile/professional" component={ProfileProfessional} />
					<Route path="/profile/social" component={ProfileSocialMedia} />
					<Route path="/profile/complementary" component={ProfileComplementary} />
					<Route path="/profile/address" component={ProfileAddress} />
					<Route path="/profile" component={Profile} />

					<Route path="/towork" component={ToWork} />
					<Route path="/terms" component={Terms} />
					<Route path="/contact" component={Contact} />
					<Route path="/faq" component={Faq} />
					<Route path="/privacy-policy" component={Policy} />
				</Switch>
			</ BrowserRouter>
		);
	}
}


export default App;
