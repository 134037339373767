import React from 'react';

import Header from '../components/login/Header';
import LoginFormRegisterProduct from '../components/login/FormRegisterProduct';


const Register = ( props ) => {


    return (
        <React.Fragment>
            <Header/>
            <div className="container">
                <div id="bg-login" className="row display-flex">
                    <div className="col col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="landing-content">
                            <h1>Seja bem-vindo a<br/>CallflexYOU!</h1>
                        </div>
                    </div>
                    <div className="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="registration-login-form">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item full">
                                    <a className="nav-link active" data-toggle="tab" href="#profile" role="tab">
                                        <svg className="olymp-register-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-register-icon" /></svg>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="home" role="tabpanel" data-mh="log-tab">
                                    <LoginFormRegisterProduct />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Register;
