import React from 'react';
import { Redirect } from 'react-router-dom'
import { Cookies } from 'react-cookie';	

const Logout = ( props ) => {
	const handleUserData = async function (){
		const cookie = new Cookies();
		cookie.remove('YouToken', []);
		cookie.remove('YouProfile', []);
    }
    handleUserData()
	return <Redirect to="/" />
}

export default Logout;