import React, { useState } from 'react';
import api from '../../utils/api';

const LoginFormAccess = ({ onSubmit }) => {

  const [password, setPassword] = useState('');
  const [user, setUser] = useState('');

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  async function handleSubmit(e) {
    document.querySelector(".submit-buttom").focus();
    e.preventDefault();
    await onSubmit({
      password,
      user
    });
  }

  const setAlert = function (message) {
    const alert = document.querySelector('#bg-login .alert.access');
    alert.classList.remove("hide");

    if (message === '') {
      alert.classList.add("hide");
      return false;
    }
    alert.innerHTML = message;
  }

  const onRestore = async function () {
    if (user.length < 6) {
      setAlert('Usuário inválido, digite um usuário válido.');
      return false;
    }

    window.preloader(true);
    const response = await api.post('/sso/user/restore', {
      mail: user
    },
      { headers: { 'Authorization': 'Bearer ' + window.key } });

    if ('undefined' === typeof response.data) {
      setAlert('Houve uma falha. Contate o suporte.')
      return false;
    }
    if ('undefined' === typeof response.data.status) {
      setAlert('Houve uma falha. Contate o suporte.')
      return false;
    }

    if (response.data.status === 'success') {
      localStorage.setItem('@YouMail', btoa(response.data.data.mail));
      localStorage.setItem('@YouRegisterCode', response.data.data.hash);
      window.location.href = '/register';
      return false;
    }

    setAlert('E-mail não cadastrado.');
    window.preloader(false);
    return false;
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center white pt-3 pb-2 mb-1">Bem-vindo <b className="strong"><span className="green">YOU</span>SER!</b></h4>
          <p className="text-center pb-2">Login de acesso</p>
        </div>
      </div>
      <form className="content" onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group label-floating">
              <label className="control-label">Digite seu e-mail ou CPF</label>
              <input
                className="form-control"
                placeholder=""
                type="text"
                autoComplete="off"
                name="user"
                id="user"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div className="form-group label-floating">
              <label className="control-label">Senha</label>
              <input
                className="form-control"
                placeholder=""
                type="password"
                autoComplete="off"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </div>
            <p className="alert access"></p>
            <div className="remember">
              <div className="checkbox">
                <label>
                  <input name="optionsCheckboxes" type="checkbox" />
                  Lembrar usuário
                </label>
              </div>
              <a href="#0" className="forgot" onClick={onRestore}>
                Recuperar conta/senha
              </a>
            </div>
            <a
              href="#0"
              className="btn btn-lg btn-primary full-width submit-buttom"
              onClick={handleSubmit}
            >
              Entrar
            </a>
            {/*
								<div className="or" />
								<a href="/#" className="btn btn-lg bg-facebook full-width btn-icon-left"><i className="fab fa-facebook-f" aria-hidden="true" />Entrar com Facebook</a>
							*/}
            <p>
              Não tem acesso?{" "}
              <a
                href="#0" className="link-login"
                onClick={() => {
                  document.querySelector('[href="#home"]').click();
                }}
              >
                Registre agora!
              </a>
            </p>
            <p><br/><br/><br/><br/><br/><br/><br/><br/><br/>
              <a
                href="/privacy-policy" className="link-login" style={{fontSize: 20}}
              >
                Política de Privacidade
              </a>
            </p>
            <p>
              <a
                href="/terms" className="link-login" style={{fontSize: 20}}
              >
                Termos de Uso
              </a>
            </p>

            
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}


export default LoginFormAccess;
