import React from 'react';

const Menu = (  ) => {
	return (
		<React.Fragment>
			<div className="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
				<div className="ui-block">
					<div className="your-profile">
						<div className="ui-block-title ui-block-title-small">
							<h6 className="title">MEU PERFIL</h6>
						</div>
						<div id="accordion" role="tablist" aria-multiselectable="true">
							<div className="card">
								<div className="card-header" role="tab" id="headingOne">
									<h6 className="mb-0">
										<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
											Configurações do Perfil
											<svg className="olymp-dropdown-arrow-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon" /></svg>
										</a>
									</h6>
								</div>
								<div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
									<ul className="your-profile-menu">
										<li>
											<a href="/profile">Informações pessoais</a>
										</li>
										<li>
											<a href="/profile/address">Endereço</a>
										</li>
										<li>
											<a href="/profile/social">Mídias sociais</a>
										</li>
										<li>
											<a href="/profile/professional">Engajamento profissional</a>
										</li>
										<li>
											<a href="/profile/complementary">Informações adicionais</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</React.Fragment>
	);
}


export default Menu;
