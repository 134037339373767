import React from 'react';

const Contact = ( props ) => {

    return (
        <React.Fragment>
            <div className="modal fade" id="main-popup-search" tabIndex={-1} role="dialog" aria-labelledby="main-popup-search" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered window-popup main-popup-search" role="document">
					<div className="modal-content">
						<a href="/#" className="close icon-close" data-dismiss="modal" aria-label="Close">
							<svg className="olymp-close-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-close-icon" /></svg>
						</a>
						<div className="modal-body">
							<form className="content" method="post">
								<div className="row">
									<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<div className="form-group label-floating">
											<h3>Contato</h3>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<div className="form-group label-floating">
											<label className="control-label">Nome</label>
											<input className="form-control" defaultValue="" type="text" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<div className="form-group label-floating">
											<label className="control-label">E-mail</label>
											<input className="form-control" defaultValue="" type="mail" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<div className="form-group label-floating">
											<label className="control-label">Mensagem</label>
											<textarea className="form-control" ></textarea>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<a href="/#" className="btn btn-primary btn-lg full-width" >Enviar</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
        </React.Fragment>
    );
}


export default Contact;
