import React from 'react';

const Search = ( props ) => {
	return (
		<React.Fragment>
            {
                /*
                    <form className="search-bar w-search notification-list friend-requests">
                        <div className="form-group with-button">
                            <input className="form-control js-user-search" placeholder="Pesquisar" type="text" />
                            <button>
                                <svg className="olymp-magnifying-glass-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon" /></svg>
                            </button>
                        </div>
                    </form>
                 */
            }
		</React.Fragment>
	);
}

export default Search;
