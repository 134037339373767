import React, { Component, useRef } from 'react';
import { Cookies } from 'react-cookie';
import api from '../../utils/api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import checkSession from '../../utils/checkSession'
import { ImageCropper, HiddenCropper } from "react-bootstrap-image-cropper";


function HiddenCropperDemo() {
	const triggerRef = useRef();
	const triggerRef1 = useRef();


	function savePicture(e) {
		var tam = e.size;
		var imgUpload = document.getElementById('img_upload').getAttribute('destiny');
		var tag = '';
		var type = '';
		var maxSize = '';
		var size = '';


		if (imgUpload === 'photo') {
			tag = 'photo'
			type = 120 / 120;
			maxSize = 300000;
		}
		else {
			tag = 'profile'
			type = 1260 / 300;
			maxSize = 500000;

		}
		var reader = new FileReader();
		reader.readAsDataURL(e);
		reader.onloadend = function () {
			var base64data = reader.result;
			console.log(base64data);

			if (tam > maxSize) {
				window.preloader(false);
				const MySwal = withReactContent(Swal)
				MySwal.fire({
					title: <p>Atenção!</p>,
					icon: 'warning',
					html: 'O tamanho máximo permitido para a imagem é de ' + maxSize + 'kb.',
					showCloseButton: false,
					showCancelButton: false,
					focusConfirm: false,
					confirmButtonColor: '#0f8084',
					cancelButtonColor: '#d33',
					confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok',
					cancelButtonText: '<i class="fa fa-thumbs-down"></i> Não',
					onOpen: () => {
					}
				}).then((action) => {
					window.location.reload();
				})
				return false;
			}
			else {
				api.post('/sso/user/images', { type: tag, image: base64data }, { headers: { 'Authorization': 'Bearer ' + checkSession() } })
					.then((response) => {

						api.get('/sso/user', { headers: { 'Authorization': 'Bearer ' + checkSession() } })
							.then((responseUser) => {
								const cookie = new Cookies();
								cookie.set('YouProfile', JSON.stringify(responseUser.data.data.user));

								window.location.reload();
							})
					})
			}
		}



	}

	return (
		<div>
			<div className="control-block-button">
				<div className="btn btn-control bg-callflex more">
					<svg className="olymp-settings-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-settings-icon" /></svg>
					<ul className="more-dropdown more-with-triangle triangle-bottom-right">
						<li>
							<a href="\#" data-toggle="modal" data-target="#update-header-profile" onClick={() => triggerRef.current.trigger()}>Alterar foto do perfil</a>
						</li>
						<li>
							<a href="\#" data-toggle="modal" data-target="#update-header-photo" onClick={() => triggerRef1.current.trigger()}>Alterar imagem de capa</a>
						</li>
					</ul>
					<input className="hide" id="img_upload"></input>
				</div>
			</div>
			<HiddenCropper
				triggerRef={triggerRef}
				onCropped={savePicture}
				cropOptions={{ aspect: 128 / 128, maxZoom: 10 }}
				outputOptions={{ maxWidth: 1260 }}
				previewOptions={{ width: 1200, height: 100 }}
				displayOptions={{ title: 'Cortar imagem', removeButtonText: 'Remover', confirmButtonText: 'Confirmar' }}
			/>

			<HiddenCropper
				triggerRef={triggerRef1}
				onCropped={savePicture}
				cropOptions={{ aspect: 1260 / 300, maxZoom: 10 }}
				outputOptions={{ maxWidth: 1260 }}
				previewOptions={{ width: 1200, height: 100 }}
				displayOptions={{ title: 'Cortar imagem', removeButtonText: 'Remover', confirmButtonText: 'Confirmar' }}
			/>
		</div>
	);
}

class Pictures extends Component {
	YouToken = ''

	constructor() {
		super();

		if (!checkSession() && window.location.pathname !== "/") {
			window.location.href = '/';
			return false;
		}

		let y = checkSession('YouProfile');
		if (y.avatar === null) y.avatar = '/img/no-image.png';
		if (y.avatar_capa === null) y.avatar_capa = '/img/top-header1.jpg';

		this.state = y;
		this.handleForm = this.handleForm.bind(this);



	}

	handleForm(e) {
		const destiny = e.target;
		var tag = 'image-capa';

		if (destiny.getAttribute('destiny') === 'photo') tag = 'image-photo';


		window.preloader(true);
		setTimeout(function () {

			const getsizePerfil = document.querySelector('.image-photo');
			const getSizeCapa = document.getElementById('image-capa')
			const sizePerfil = getsizePerfil.getAttribute('size');
			const sizeCapa = getSizeCapa.getAttribute('size');

			if (sizePerfil !== '' || sizeCapa == '') {
				window.preloader(false);
				const MySwal = withReactContent(Swal)
				MySwal.fire({
					title: <p>Atenção!</p>,
					icon: 'warning',
					html: 'O tamanho máximo permitido para a imagem é de ' + sizePerfil || sizeCapa + 'kb.',
					showCloseButton: false,
					showCancelButton: false,
					focusConfirm: false,
					confirmButtonColor: '#0f8084',
					cancelButtonColor: '#d33',
					confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok',
					cancelButtonText: '<i class="fa fa-thumbs-down"></i> Não',
					onOpen: () => {
					}
				}).then((action) => {
					window.location.reload();
				})
				return false;
			}

		}, 1500)
	}

	onError = () => {
		let t = this.state;
		t.avatar = '/img/no-image.png';
		this.setState(t)
	}

	render() {
		return (
			<React.Fragment>
				<div className="container">
					<div className="row">

						<div className="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="ui-block">
								<div className="top-header">
									<div className="top-header-thumb">
										<img id="image-capa" src={this.state.avatar_capa} alt="nature" />
									</div>
									<div className="profile-section">
										<div style={{ padding: 16 }}>

										</div>
										{
											(
												(
													this.props.edit && this.props.edit === 'off'
												)
													?
													''
													:
													<HiddenCropperDemo />
											)
										}
									</div>
									<div className="top-header-author">
										<a href="/profile" className="author-thumb">
											<img className="image-photo" id="image-photo" src={this.state.avatar} alt="author1" onError={this.onError} />
										</a>
										<div className="author-content">
											<a href="/profile" className="h4 author-name">{this.state.name}</a>
											<div className="country">{this.state.mail}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>


		)
	}
}

export default Pictures;