import React from 'react';

import Header from '../components/login/Header';

const Terms = ( props ) => {

    return (
        <React.Fragment>
            <div className="stunning-header bg-primary-opacity">
                <Header/>
                    <div className="header-spacer--standard" />
                        <div className="stunning-header-content">
                        <h1 className="stunning-header-title">FAQ</h1>
                    </div>
                    <div className="content-bg-wrap stunning-header-bg1" />
                    </div>
                    <section className="mb60">
                        <div className="container">
                            <div className="row">
                                <div className="col col-xl-8 m-auto col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div id="accordion" role="tablist" aria-multiselectable="true" className="accordion-faqs">
                                        <div className="card">
                                            <div className="card-header" role="tab" id="headingOne">
                                                <h3 className="mb-0">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                        Oque é o CallflexYOU
                                                        <span className="icons-wrap">
                                                        <svg className="olymp-plus-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-plus-icon" /></svg>
                                                        <svg className="olymp-accordion-close-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-accordion-close-icon" /></svg>
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="collapse_1" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                                <p>
                                                    É um plataforma
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" role="tab" id="headingOne">
                                                <h3 className="mb-0">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse_2" aria-expanded="true" aria-controls="collapse_2" className="collapsed">
                                                        A quem se destina?
                                                        <span className="icons-wrap">
                                                        <svg className="olymp-plus-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-plus-icon" /></svg>
                                                        <svg className="olymp-accordion-close-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-accordion-close-icon" /></svg>
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="collapse_2" className="collapse" role="tabpanel" aria-labelledby="headingOne">
                                                <p>
                                                    Para você.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" role="tab" id="headingOne">
                                                <h3 className="mb-0">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse_3" aria-expanded="true" aria-controls="collapse_3" className="collapsed">
                                                        Oque é Youniversity?
                                                        <span className="icons-wrap">
                                                        <svg className="olymp-plus-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-plus-icon" /></svg>
                                                        <svg className="olymp-accordion-close-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-accordion-close-icon" /></svg>
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="collapse_3" className="collapse" role="tabpanel" aria-labelledby="headingOne">
                                                <p>
                                                    Plataforma de LMS
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" role="tab" id="headingOne">
                                                <h3 className="mb-0">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="collapsed">
                                                        Registro de usuário
                                                        <span className="icons-wrap">
                                                        <svg className="olymp-plus-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-plus-icon" /></svg>
                                                        <svg className="olymp-accordion-close-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-accordion-close-icon" /></svg>
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="collapseOne" className="collapse" role="tabpanel" aria-labelledby="headingOne">
                                                <p>
                                                    O Registro de usuario é gratuito.
                                                </p>
                                                <p>
                                                    Na pagina principal, clique em Registre-se aqui e preencha os campos solicitados. Um e-mail será enviado com um código de validação para registrar sua senha pessoal de acesso.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" role="tab" id="headingOne-1">
                                                <h3 className="mb-0">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne" className="collapsed">
                                                        Recuperação de senha
                                                        <span className="icons-wrap">
                                                        <svg className="olymp-plus-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-plus-icon" /></svg>
                                                        <svg className="olymp-accordion-close-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-accordion-close-icon" /></svg>
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div id="collapseOne-1" className="collapse" role="tabpanel" aria-labelledby="headingOne-1">
                                                <p>
                                                    Clique em *esqueci a senha* e um e-mail será enviado com um código de validação para registrar sua nova senha pessoal de acesso.
                                                </p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="footer footer-full-width faq" id="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-12" >
                                    <div className="sub-footer-copyright">
                                        Copyright <a href="/">Callflex Sistemas Digitais</a> All Rights Reserved 2020
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </React.Fragment>
    );
}


export default Terms;
