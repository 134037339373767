import React from 'react';

const MenuAbout = ( props ) => {

	return (
		<React.Fragment>
			<ul className="about-olympus">
				<li>
					<a href="/terms" target='_blank'  rel="noopener noreferrer" >
						<span>Termos e Condições</span>
					</a>
				</li>
				<li>
					<a href="/faq" target='_blank'  rel="noopener noreferrer" >
					<	span>FAQs</span>
					</a>
				</li>
				<li>
					<a href="#0" data-toggle="modal" data-target="#main-popup-search" >
						<span>Contato</span>
					</a>
				</li>
			</ul>
		</React.Fragment>
	);
}


export default MenuAbout;
