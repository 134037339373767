import React from 'react';

import Loading from './components/loading/Loading.jsx';
import Routes from './main/Routes.jsx';

window.key = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW4iOiIqIiwiaXAiOiIxNzcuMTI5LjE5Ni41In0.o_DvemVjl8AwAPMh38_bipJ-eERNblMjcNu35_p9MW4';

function App() {

	return (
		<React.Fragment>
			<Loading key="loading"/>
			<Routes key="layout"/> 
		</React.Fragment>
	);
}

export default App;
