import React, { useState, useEffect } from 'react';
import checkSession from '../utils/checkSession';

import Header from '../components/logged/Header';
import Home from '../components/home/Home';
import Footer from './Footer';


const HomeMe = ( props ) => {
	const [ YouProfile, setYouProfile ] = useState([]);

	useEffect( () => { 
		async function loadProfile(){
			setYouProfile( checkSession( 'YouProfile' ) )
		}
		loadProfile()
	}, []);

	if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/logout';
		return false;
	}

	return (
		<React.Fragment>
			<Header YouProfile={YouProfile} title="Início"/>
			<Home YouProfile={checkSession( 'YouProfile' )} />
			<Footer />
		</React.Fragment>
	)
}


export default HomeMe;