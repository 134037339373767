import React from 'react';

import Header from '../components/login/Header';
import LoginFormRegisterCheck from '../components/login/FormRegisterCheck';


const Register = ( props ) => {

    return (
        <React.Fragment>
            <Header/>
            <div className="container">
                <div id="bg-login" className="row display-flex height-login bg-login-step-2">
                    <div id="step-2" className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="pl-4 pt-4 pb-4">
                            <p className="font-18 white">Soluções Callflex</p>
                            <p className="mb-2"><span className="font-52 white">melhorando</span><br />
                            <span className="font-52 white">resultados com</span><br/>
                            <span className="font-61 white">o seu</span> <span className="font-61 green">discador</span></p>
                            <ul className="white font-18 list-bg-login mb-4">
                                <li>Top ! Otimizando seus relatórios</li>
                                <li>Novas Funcionalidades</li>
                            </ul>
                            <p className="pt-4"><img src="img/callflex_youniversity.svg" height={30} alt="Callflex" /> </p>
                        </div>
                    </div>
                    <div className="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="registration-login-form">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item full">
                                    <a className="nav-link active" data-toggle="tab" href="#profile" role="tab">
                                        <img src="/img/login/user_add.svg" alt="CallflexYou" />
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="home" role="tabpanel" data-mh="log-tab">
                                    <LoginFormRegisterCheck />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer footer-full-width" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-12 col-md-12 col-sm-12 col-12" >
                            <div className="sub-footer-copyright">
                            © Copyright -<a href="/">Callflex Sistemas Digitais</a> All Rights Reserved 2020
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Register;
