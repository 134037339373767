import React from 'react';

const Posts = ( props ) => {
	const { post } = props;
	
	return (
		<React.Fragment>
			<div className="ui-block">
				<article className="hentry post has-post-thumbnail shared-photo">
					<div className="post__author author vcard inline-items">
						<div className="author-date">
							<a className="h6 post__author-name fn" href="02-ProfilePage.html">{post.name}</a>
							<div className="post__date">
								<time className="published" dateTime={post.date_entry}>
									{post.date_entry}
								</time>
							</div>
						</div>
					</div>
					<p dangerouslySetInnerHTML={{__html: post.post.text}}></p>

					{post.post.attach.map( attach => (
						<div className="post-thumb" key={attach.id}>
							<img src={attach.href} alt="CallFlexYou" />
						</div>
					))}

				</article>
			</div>
		</React.Fragment>
	);
}


export default Posts;



 