import React from 'react';
import Contact from '../../main/Contact';


const Header = (  ) => {
	return (
		<React.Fragment>
			<div className="header--standard header--standard-landing animated" id="header--standard">
				<div className="container">
					<div className="header--standard-wrap">
						<a href="/" className="logo">
						<div className="img-wrap">
							<img src="/img/logo-callflex-you.svg" style={{width: '200px'}} alt="Callflex"></img>
							<img src="/img/logo-callflex-you.svg" style={{width: '200px'}} className="logo-colored" alt="Callflex"></img>
						</div>
						<div className="title-block">
						</div>
						</a>
						<div className="nav nav-pills nav1 header-menu">
						<div className="mCustomScrollbar ps ps--theme_default ps--active-x ps--active-y" data-ps-id="82d4e10d-3f49-b38d-0b0e-b88008ae5070">
							<ul>
							<li className="menu-search-item">
								<a href="/#" className="nav-link" data-toggle="modal" data-target="#main-popup-search">
									Contato
								</a>
							</li>
							</ul>
							<div className="ps__scrollbar-x-rail" style={{width: '855px', left: '0px', bottom: '0px'}}><div className="ps__scrollbar-x" tabIndex={0} style={{left: '0px', width: '833px'}} /></div><div className="ps__scrollbar-y-rail" style={{top: '0px', height: '80px', right: '0px'}}><div className="ps__scrollbar-y" tabIndex={0} style={{top: '0px', height: '14px'}} /></div></div>
						</div>
					</div>
				</div>
			</div>
			<Contact />

			<div className="header-spacer--standard"></div>
		</React.Fragment>
	);
}


export default Header;
