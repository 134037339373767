import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';	
import api from '../utils/api';

import Header from '../components/logged/Header';
import ToWork from '../components/towork/ToWork';
import Footer from './Footer';

const Profiles = ( props ) => {

	
	const checkCookie = ( name ) => {
		const me = new Cookies();
		const You = me.get( name ) || '';
		if( You === '' ) return false;
		return You;
	}
	
	const YouProfile = checkCookie( 'YouProfile' );

	const [ access, setAccess ] = useState([]);


	useEffect( () => { 

		async function loadAccess(){

			const response =  api.get('/sso/towork', {headers: {'Authorization': 'Bearer '+checkCookie( 'YouToken' )}  } );

			response.then( res => {
				if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.services ) window.location.href = '/logout';
				
				let accessProducts = {
					'1': {'id': 1,'status': false,'img': "/img/elements/conversar.svg", 'title': 'Conversar', 'href': 'https://www.callflex.com.br/plataforma-omnichannel', 'content': 'Plataforma Omnichannel', 'text': 'Iniciou um atendimento por whatsapp que terminou por e-mail? Ótimo, nosso multiconversas está preparado. Um atendimento contínuo, com histórico e sem intervalo', 'servers': [] },
					'2': {'id': 2,'status': false,'img': "/img/elements/discar.svg",'title': 'Discar e atender', 'href': 'https://www.callflex.com.br/discador-preditivo-adaptativo','content': 'Discador Preditivo e Adaptativo e URA', 'text': 'O discador mais premiado do Brasil, com mais autonomia nas tarefas diárias, agora em uma plataforma ainda mais intuitiva e com dashboards que permitem uma visão ampliada da sua operação.','servers': []},
					'3': {'id': 3,'status': false,'img': "/img/elements/humanizar.svg",'title': 'Humanizar', 'href': 'https://www.callflex.com.br/agentes-digitais','content': 'Agentes Digitais', 'text': 'Solução de atendimento com Robôs personalizados para receber chamada, fazer uma oferta ou até mesmo realizar todo processo de negociação.','servers': []},
					'4': {'id': 4,'status': false,'img': "/img/elements/gerenciar.svg",'title': 'Gerenciar', 'href': 'https://www.callflex.com.br/tabulador-atendimento','content': 'Tabulador de Atendimento', 'text': 'Um tabulador de atendimento para ajudar a operacionalizar as suas vendas, as estratégias de relacionamento com o cliente e a inteligência do seu negócio.', 'servers': []},
					'5': {'id': 5,'status': false,'img': "/img/elements/mensurar.svg",'title': 'Mensurar', 'href': 'https://www.callflex.com.br/extrator-relatorios-personalizados','content': 'Extrator de Relatórios Personálizados', 'text': 'Um novo jeito de construir e extrair relatórios, de forma fácil e rápida. Conecte diversas fontes de dados com linguagens distintas. Mais controle da sua operação, otimizando o seu dia.','servers': []},
				};

				if( res.data.data.services.length ){
					res.data.data.services.forEach(service => {
						if( 'undefined' !== typeof service.products ){
							if( service.products.length ){
								service.products.forEach(products => {
									if( 'undefined' !== accessProducts[ products.product_id ] ){
										accessProducts[ products.product_id ].status = true;
										products.servers.forEach(element => {
											accessProducts[ products.product_id ].servers.push( element ) ;	
										});
										
									}
								});
							}
						}
					});
				}

				setAccess( accessProducts )
			})
		}
		loadAccess()
	}, []);


	return (
		<React.Fragment>
			<Header YouProfile={YouProfile} title="Trabalhar"/>

				<div className="container services-access">
					<div className="row services">
						{Object.keys( access ).map( x => (
							<ToWork  key={x} e={access[x]} type='ok'/>
						))}

						{Object.keys( access ).map( x => (
							<ToWork  key={x} e={access[x]} type='no'/>
						))}

					</div>
				</div>
				
			<Footer />
		</React.Fragment>
	);
}


export default Profiles;
