import React, {Component} from 'react';
import checkSession from '../../utils/checkSession'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../../utils/api';

import Menu from './Menu';

class FormPersonSocialMedia extends Component {
	YouToken = ''

	constructor() {
		super();
		this.state = { user: [] };

		if( !checkSession() && window.location.pathname !== "/" ) {
			window.location.href = '/logout';
			return false;
		}

		this.YouToken = checkSession();
		this.handleForm = this.handleForm.bind(this)
	}
  
	componentDidMount() {
		if( !this.YouToken ) return false;
		const response =  api.get('/sso/user/social', {headers: {'Authorization': 'Bearer '+this.YouToken}  } );

		response.then( res => {
			this.setState({ user: res.data.data.user })
		})
	}

	handleForm( e ){
		const MySwal = withReactContent(Swal)
		MySwal.fire({
			title: <p>Atenção!</p>,
			icon: 'warning',
			html: 'Tem certeza que deseja salvar as alterações?',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonColor: '#0f8084',
			cancelButtonColor: '#d33',
			confirmButtonText:	'<i class="fa fa-thumbs-up"></i> Sim',
			cancelButtonText: '<i class="fa fa-thumbs-down"></i> Não',
			onOpen: () => {
			  console.log('oooooo')
			}
		}).then((action) => {
			if( !action.value ) return false;
			window.preloader( true );
			const user = this.state.user

			api.post('/sso/user/social', user, {headers: {'Authorization': 'Bearer '+this.YouToken}  } )
			.then( (response) => {
				window.preloader( false );
				MySwal.fire({
					icon: ( response.status === 200 && response.data.status === 'success' ? 'success' : 'error' ),
					title: ( response.status === 200 && response.data.status === 'success' ? 'Salvo com sucesso!' : 'Falha ao salvar!' ),
					showConfirmButton: false,
					timer: 1500
				})
			})
		})
		e.preventDefault();
	}

	setUser( col, value ) {
		let me = this.state.user;

		me[ col ] = value;
		this.setState( { user: me })
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="container cur" time={new Date().getTime()}>
					<div className="row">
						<div className="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
							<div className="ui-block">
								<div className="ui-block-title">
									<h6 className="title">Mídias Sociais</h6>
								</div>
								<div className="ui-block-content">
									<form>
										<div className="row">
											<div className="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group with-icon label-floating">
													<label className="control-label">Facebook</label>
													<input className="form-control" type="text" defaultValue={this.state.user.social_facebook} onChange={e => this.setUser( 'social_facebook', e.target.value )} maxLength="100"/>
													<i className="fab fa-facebook-f c-facebook" aria-hidden="true" />
												</div>
												<div className="form-group with-icon label-floating is-empty">
													<label className="control-label">Instagram</label>
													<input className="form-control" type="text" defaultValue={this.state.user.social_instagram} onChange={e => this.setUser( 'social_instagram', e.target.value )} maxLength="100"/>
													<i className="fab fa-instagram c-instagram" aria-hidden="true" />
												</div>
												<div className="form-group with-icon label-floating">
													<label className="control-label">Twitter</label>
													<input className="form-control" type="text" defaultValue={this.state.user.social_twitter} onChange={e => this.setUser( 'social_twitter', e.target.value )} maxLength="100"/>
													<i className="fab fa-twitter c-twitter" aria-hidden="true" />
												</div>
												<div className="form-group with-icon label-floating">
													<label className="control-label">Linkedin</label>
													<input className="form-control" type="text" defaultValue={this.state.user.social_linkedin} onChange={e => this.setUser( 'social_linkedin', e.target.value )} maxLength="100"/>
													<i className="fab fa-linkedin c-linkedin" aria-hidden="true" />
												</div>
												<div className="form-group with-icon label-floating">
													<label className="control-label">Site / Blog</label>
													<input className="form-control" type="text" defaultValue={this.state.user.social_site} onChange={e => this.setUser( 'social_site', e.target.value )} maxLength="100"/>
													<i className="fab fa-globe c-globe" aria-hidden="true" />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Itens de Destaque Social (hobbies, ações, envolvimentos)</label>
													<input className="form-control" placeholder="" type="text" defaultValue={this.state.user.social_highlight} onChange={e => this.setUser( 'social_highlight', e.target.value )} maxLength="300"/>
													<span className="material-input" />
												</div>
											</div>
											<div className="col col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Sobre (Resumo do Perfil)</label>
													<textarea className="form-control" defaultValue={this.state.user.bio} onChange={e => this.setUser( 'bio', e.target.value )} maxLength="500" />
													<span className="material-input" />
												</div>
											</div>
											<div className="col col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Interesses do Usuário (empresas, negócios, temas, hashtags)</label>
													<textarea className="form-control" defaultValue={this.state.user.interests} onChange={e => this.setUser( 'interests', e.target.value )} maxLength="500"/>
													<span className="material-input" />
												</div>
											</div>
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
											</div>
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
												<button className="btn btn-purple btn-lg full-width" onClick={this.handleForm}>Salvar</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<Menu />
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default FormPersonSocialMedia;