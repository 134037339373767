import React from 'react';
import MenuDetail from './MenuDetail';
import MenuAbout from './MenuAbout';


const Menu = ( props ) => {
	const YouProfile = props.YouProfile;

	return (
		<React.Fragment>
			<div>
				<div className="fixed-sidebar">

					<div className="fixed-sidebar-left sidebar--small" id="sidebar-left">
						<a href="#0" className="logo " onClick={() => {window.location.reload()}}>
							<div className="img-wrap">
								<img src="/img/clx.png" alt="CallflexYOU" />
							</div>
						</a>
						<div className="mCustomScrollbar" data-mcs-theme="dark">
							<ul className="left-menu">
								<MenuDetail hide="true"/>         
							</ul>
						</div>
					</div>

					<div className="fixed-sidebar-left sidebar--large" id="sidebar-left-1">
						<a href="#0" className="logo " onClick={() => {window.location.reload()}}>
							<div className="img-wrap">
								<img src="/img/callflexyou.png" alt="CallflexYOU" />
							</div>
						</a>
						<div className="mCustomScrollbar" data-mcs-theme="dark">
							<ul className="left-menu">
								<MenuDetail/>
							</ul>
						</div>
					</div>
				</div>

				<div className="fixed-sidebar fixed-sidebar-responsive">
					<div className="fixed-sidebar-left sidebar--small" id="sidebar-left-responsive">
						<a href="#0" className="logo js-sidebar-open" onClick={() => {window.location.reload()}}>
							<img src="/img/clx.png" alt="CallflexYOU" />
						</a>
					</div>
					<div className="fixed-sidebar-left sidebar--large" id="sidebar-left-1-responsive">
						<a href="#0" className="logo js-sidebar-open" onClick={() => {window.location.reload()}}>
							<div className="img-wrap">
								<img src="/img/callflexyou.png" alt="CallflexYOU" />
							</div>
						</a>
						<div className="mCustomScrollbar" data-mcs-theme="dark">
							<div className="ui-block-title ui-block-title-small">
								<div className="description-toggle mb-0">
									<div className="description-toggle-content">
										<p>Light / Dark</p>
									</div>
									<div className="togglebutton">
										<label>
											<input type="checkbox" defaultChecked/>
										</label>
									</div>
								</div>
							</div>
							<div className="control-block"> 
								<div className="author-page author vcard inline-items">
									<div className="author-thumb">
										<img alt="author" src={YouProfile.avatar} className="avatar" />
									</div>
									<a href="/profile" className="author-name fn">
										<div className="author-title">
											{YouProfile.name}
										</div>
										<span className="author-subtitle">{YouProfile.mail}</span>
									</a>
								</div>
							</div>
							<ul className="left-menu">
								<MenuDetail/>
								<li>
									<a href="/logout">
										<img src="/img/menu/logout.svg" alt="CallflexYOU" className="ico-menu"/>
										<span className="left-menu-title">SAIR</span>
									</a>
								</li>
							</ul>
							<div className="ui-block-title ui-block-title-small">
								<h6 className="title">SOBRE CALLFLEXYOU</h6>
							</div>
							<MenuAbout />
						</div>
					</div>
				</div>
			</div>

		</React.Fragment>
	);
}


export default Menu;
