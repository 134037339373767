import React, {Component} from 'react';
import checkSession from '../../utils/checkSession'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../../utils/api';

import Menu from './Menu';


class FormPersonComplementary extends Component {
	YouToken = ''

	constructor() {
	  super();
	  this.state = { user: [] };

	  if( !checkSession() && window.location.pathname !== "/" ) {
		  window.location.href = '/logout';
		  return false;
	  }

	  this.YouToken = checkSession();

	  this.handleForm = this.handleForm.bind(this)
	}
  
	componentDidMount() {
		if( !this.YouToken ) return false;
		const response =  api.get('/sso/user/aditional', {headers: {'Authorization': 'Bearer '+this.YouToken}  } );

		response.then( res => {
			if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.user ) window.location.href = '/logout';
			
			this.setState({ user: res.data.data.user })
		})

	}
	componentWillUnmount() {
	}

	handleForm( e ){
		const MySwal = withReactContent(Swal)
		MySwal.fire({
			title: <p>Atenção!</p>,
			icon: 'warning',
			html: 'Tem certeza que deseja salvar as alterações?',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonColor: '#0f8084',
			cancelButtonColor: '#d33',
			confirmButtonText:	'<i class="fa fa-thumbs-up"></i> Sim',
			cancelButtonText: '<i class="fa fa-thumbs-down"></i> Não',
			onOpen: () => {
			  console.log('oooooo')
			}
		}).then((action) => {
			if( !action.value ) return false;
			window.preloader( true );
			const user = this.state.user

			api.post('/sso/user/aditional', user, {headers: {'Authorization': 'Bearer '+this.YouToken}  } )
			.then( (response) => {
				if(  'undefined' === typeof response.data.data || 'undefined' === typeof response.data.data.affected_rows ) window.location.href = '/logout';

				window.preloader( false );
				MySwal.fire({
					icon: ( response.status === 200 && response.data.status === 'success' ? 'success' : 'error' ),
					title: ( response.status === 200 && response.data.status === 'success' ? 'Salvo com sucesso!' : 'Falha ao salvar!' ),
					showConfirmButton: false,
					timer: 1500
				})
			})
		})
		e.preventDefault();
	}

	setUser( col, value ) {
		let me = this.state.user;

		me[ col ] = value;
		this.setState( { user: me })
	}
  
	render() {
		return (
			<React.Fragment>
				<div className="container cur" time={new Date().getTime()}>
					<div className="row">
						<div className="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
							<div className="ui-block">
								<div className="ui-block-title">
									<h6 className="title">Informações adicionais</h6>
								</div>
								<div className="ui-block-content">
									<form>
										<div className="row">
											<div className="col col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Segmentos onde pretende atuar</label>
													<input className="form-control" placeholder="" type="text" defaultValue={this.state.user.performance_segments} onChange={e => this.setUser( 'performance_segments', e.target.value )}/>
													<span className="material-input" />
												</div>
											</div>
											<div className="col col-lg-12 col-md-12 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Expêriencias Profissionais</label>
													<textarea className="form-control" defaultValue={this.state.user.professional_experience} onChange={e => this.setUser( 'professional_experience', e.target.value )}/>
													<span className="material-input" />
												</div>
											</div>					        
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
											</div>
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
												<button className="btn btn-purple btn-lg full-width" onClick={this.handleForm}>Salvar</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<Menu />
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default FormPersonComplementary;