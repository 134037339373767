import React from 'react';
import checkSession from '../utils/checkSession'
import Header from '../components/logged/Header';
import MenuResponsive from '../components/profile/MenuResponsive';
import Pictures from '../components/profile/Pictures';
import FormPerson from '../components/profile/FormPerson';
import Footer from './Footer';



const Profiles = ( props ) => {
	if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/logout';
		return false;
	}

	const YouProfile = checkSession( 'YouProfile' );

	return (
		<React.Fragment>
			<MenuResponsive />
			<Header YouProfile={YouProfile} title="Perfil"/>
			<Pictures YouProfile={YouProfile} />
			<FormPerson YouProfile={YouProfile} />
			<Footer />
		</React.Fragment>
	);
}


export default Profiles;
