import React from 'react';

const MenuResponsive = (  ) => {
	return (
		<React.Fragment>
			<div className="profile-settings-responsive">
				<a href="\#" className="js-profile-settings-open profile-settings-open">
					<i className="fa fa-angle-right" aria-hidden="true" />
					<i className="fa fa-angle-left" aria-hidden="true" />
				</a>
				<div className="mCustomScrollbar" data-mcs-theme="dark">
					<div className="ui-block">
						<div className="your-profile">
							<div className="ui-block-title ui-block-title-small">
								<h6 className="title">MEU PERFIL</h6>
							</div>
							<div id="accordion1" role="tablist" aria-multiselectable="true">
								<div className="card">
									<div className="card-header" role="tab" id="headingOne-1">
										<h6 className="mb-0">
											<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne">
												Configurações do Perfil
												<svg className="olymp-dropdown-arrow-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon" /></svg>
											</a>
										</h6>
									</div>
									<div id="collapseOne-1" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
										<ul className="your-profile-menu">
											<li>
												<a href="/profile">Informações pessoais</a>
											</li>
											<li>
												<a href="/profile/address">Endereço</a>
											</li>
											<li>
												<a href="/profile/social">Mídias sociais</a>
											</li>
											<li>
												<a href="/profile/professional">Engajamento profissional</a>
											</li>
											<li>
												<a href="/profile/complementary">Informações adicionais</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}


export default MenuResponsive;


