import React, { useState, useEffect } from 'react';
import checkSession from '../utils/checkSession'
import Header from '../components/logged/Header';
import MenuResponsive from '../components/profile/MenuResponsive';
import Pictures from '../components/profile/Pictures';
import FormAddress from '../components/profile/FormAddress.jsx';
import Footer from './Footer';



const Profiles = ( props ) => {

	const [ YouProfile, setYouProfile ] = useState([]);

	useEffect( () => { 
		async function loadDevs(){
			setYouProfile( checkSession( 'YouProfile' ) )
		}
		loadDevs()
	}, []);


	return (
		<React.Fragment>
			<MenuResponsive />
			<Header YouProfile={YouProfile} title="Perfil"/>
			<Pictures YouProfile={YouProfile} />
			<FormAddress YouProfile={YouProfile} />
			<Footer />
		</React.Fragment>
	);
}


export default Profiles;
