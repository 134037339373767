import React, { useState, useEffect } from 'react';
import api from '../../utils/api';


const LoginFormRegisterCheck = (  ) => {

	const [ code, setCode ] = useState('');
	const [ pwd, setPass ] = useState('');
	const [ pwd2, setPass2 ] = useState('');
	const [ alertMessage, setAlert ] = useState('');

	useEffect( () => {
		if( localStorage.getItem('@YouMail') === '' || localStorage.getItem('@YouMail') === null ){
			window.location.href = "/";
		}
	})

    async function handleSubmit( e ){
		e.preventDefault();
		
		if( code.length !== 5 ){
			setAlert('Código Inválido')
			return false;
		}
		if( pwd.length < 8 ){
			setAlert('Senha deve possuir mais de 8 caracteres')
			return false;
		}
		if( pwd !== pwd2 ){
			setAlert('Senha e Confirmar senha não coincidem')
			return false;
        }

		const response = await api.post('/sso/user/register/check', {
			hash: localStorage.getItem('@YouRegisterCode'),
			code,
			mail: atob(localStorage.getItem('@YouMail')),
			pwd,
			pwd2
		}, 
		{headers: {'Authorization': 'Bearer '+window.key }  });

		if( 'undefined' === typeof response.data ){
			setAlert('Houve uma falha. Contate o suporte.')
			return false;
		}
		if( 'undefined' === typeof response.data.status ){
			setAlert('Houve uma falha. Contate o suporte.')
			return false;
		}
		if( response.data.status === 'error' ){
			setAlert(response.data.message)
			return false;
		}

		localStorage.removeItem('@YouMail')
        
        window.location.href = "/";

	}


	return (
		<React.Fragment>
			<div className="title h6">Registro no YOU</div>
			<form className="content register" onSubmit={handleSubmit} autoComplete="off">
				<div className="row">
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<p>
							Você receberá um e-mail com um código para validação. Digite aqui em baixo e cadastre sua nova senha.
							<br/><br/>
						</p>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Código de Validação</label>
							<input className="form-control" placeholder="" type="text" autoComplete="off"  maxLength="5" value={code} onChange={e => setCode(e.target.value) }/>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Senha</label>
							<input className="form-control" placeholder="" type="password" autoComplete="off" maxLength="12" value={pwd} onChange={e => setPass(e.target.value) }/>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Confirmar Senha</label>
							<input className="form-control" placeholder="" type="password" autoComplete="new-password" maxLength="12" value={pwd2} onChange={e => setPass2(e.target.value) }/>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<p className="alert">{alertMessage}</p>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<a href="/#" className="btn btn-primary btn-lg full-width" onClick={handleSubmit}>Validar</a>
					</div>
				</div>
			</form>
		</React.Fragment>
	);
}


export default LoginFormRegisterCheck;
