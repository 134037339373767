import React from 'react';
import checkSession from '../../utils/checkSession';

import Menu from './Menu';
import MenuUser from './HeaderMenuUser';
import HeaderSearch from './HeaderSearch';


const GeneralHeader = ( props ) => {
	if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/';
		return false;
	}

	const YouProfile = props.YouProfile;
	window.YouProfile = YouProfile;
	

	return (
		<React.Fragment>
			<Menu YouProfile={YouProfile}/>

			<header className="header" id="site-header">
				<div className="page-title">
					<h6>{props.title}</h6>
				</div>
				<div className="header-content-wrapper">
					<HeaderSearch />
					<div className="control-block">
						<MenuUser  YouProfile={YouProfile} user={props.user}/>
					</div>
				</div>
			</header>

			<header className="header header-responsive" id="site-header-responsive">
			</header>
			<div className="header-spacer"></div>
		</React.Fragment>
	);
}


export default GeneralHeader;
