import React from 'react';

const MenuUser = ( props ) => {
	var YouProfile = props.YouProfile;

	if( YouProfile.avatar === '' || YouProfile.avatar === null ) YouProfile.avatar = '/img/no-image.png';

	const onError  = function(){
		YouProfile.avatar = '/img/no-image.png';
	}

	return (
		<React.Fragment>
			
			<div className="author-page author vcard inline-items more">
				<div className="author-thumb">
					<img alt="author" src={YouProfile.avatar} className="avatar" onError={onError} />
					<div className="more-dropdown more-with-triangle">
						<div className="mCustomScrollbar" data-mcs-theme="dark">
							<div className="ui-block-title ui-block-title-small">
								<div className="description-toggle mb-0">
									<div className="description-toggle-content">
										<p>Light / Dark</p>
									</div>
									<div className="togglebutton">
										<label>
											<input type="checkbox" defaultChecked/>
										</label>
									</div>
								</div>
							</div>
							<div className="ui-block-title ui-block-title-small">
								<h6 className="title">Sua conta</h6>
							</div>
							<ul className="account-settings">
								<li>
									<a href="/profile">
										<svg className="olymp-menu-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-menu-icon" /></svg>
										<span>Configurações de Perfil</span>
									</a>
								</li>
								<li>
									<a href="/logout">
										<svg className="olymp-logout-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-logout-icon" /></svg>
										<span>Sair</span>
									</a>
								</li>
							</ul>
							<div className="ui-block-title ui-block-title-small">
								<h6 className="title">Sobre CallflexYOU</h6>
							</div>
							<ul>
								<li>
									<a href="/terms" target='_blank'  rel="noopener noreferrer" >
										<span>Termos e Condições</span>
									</a>
								</li>
								<li>
									<a href="/faq" target='_blank'  rel="noopener noreferrer" >
									<	span>FAQs</span>
									</a>
								</li>
								<li>
									<a href="#0" data-toggle="modal" data-target="#main-popup-search" >
										<span>Contato</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<a href="#0" onClick={ev => {ev.preventDefault();}} className="author-name fn">
					<div className="author-title">
						{YouProfile.name} <svg className="olymp-dropdown-arrow-icon"><use xlinkHref="/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon" /></svg>
					</div>
					<span className="author-subtitle">{YouProfile.mail}</span>
				</a>
			</div>
		</React.Fragment>
	);
}


export default MenuUser;
