import React from 'react';

const Corp = ( props ) => {
	const { handeOpen, img } = props;
	
	return (
		<React.Fragment>
            <li>
                <article className="hentry post">
                    <div style={{ cursor: "pointer" }} className="youniversity" onTouchStart={handeOpen} onTouchEnd={handeOpen} onClick={handeOpen}>
                        <img src={img} alt="" />
                    </div>
                </article>
            </li>
		</React.Fragment>
	);
}


export default Corp;



 