import React, { useState, useEffect }  from 'react';
import checkSession from '../../utils/checkSession';
import api from '../../utils/api';

import Itens from './Itens';   
import Products from './Products';   

import Posts from './Posts';   

const Access = (  ) => {	
	const [ posts, setPosts ] = useState([]);
	const [ access, setAccess ] = useState([]);
	const [ accessNo, setAccessNo ] = useState(false);

	useEffect( () => { 
		if( !checkSession() && window.location.pathname !== "/" ) {
			window.location.href = '/logout';
			return false;
		}

		async function loadPosts(){
			const response =  api.get('/sso/post', {headers: {'Authorization': 'Bearer '+checkSession()}  } );
			response.then( res => {
				let posts = [];
				if(  'undefined' !== typeof res.data.data || 'undefined' !== typeof res.data.data.posts ) posts = res.data.data.posts;

				setPosts( posts )
			})
		}
		loadPosts()

		async function loadAccess(){
			const response =  api.get('/sso/towork', {headers: {'Authorization': 'Bearer '+checkSession()}  } );
			response.then( res => {
				if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.services ) window.location.href = '/logout';
				
				let accessProducts = {
					'1': {'id': 1,'status': false,'img': "/img/elements/conversar.svg", 'title': 'Conversar', 'href': 'https://www.callflex.com.br/plataforma-omnichannel', 'content': 'Plataforma Omnichannel', 'text': 'Iniciou um atendimento por whatsapp que terminou por e-mail? Ótimo, nosso multiconversas está preparado. Um atendimento contínuo, com histórico e sem intervalo', 'servers': [] },
					'2': {'id': 2,'status': false,'img': "/img/elements/discar.svg",'title': 'Discar e atender', 'href': 'https://www.callflex.com.br/discador-preditivo-adaptativo','content': 'Discador Preditivo e Adaptativo e URA', 'text': 'O discador mais premiado do Brasil, com mais autonomia nas tarefas diárias, agora em uma plataforma ainda mais intuitiva e com dashboards que permitem uma visão ampliada da sua operação.','servers': []},
					'3': {'id': 3,'status': false,'img': "/img/elements/humanizar.svg",'title': 'Humanizar', 'href': 'https://www.callflex.com.br/agentes-digitais','content': 'Agentes Digitais', 'text': 'Solução de atendimento com Robôs personalizados para receber chamada, fazer uma oferta ou até mesmo realizar todo processo de negociação.','servers': []},
					'4': {'id': 4,'status': false,'img': "/img/elements/gerenciar.svg",'title': 'Gerenciar', 'href': 'https://www.callflex.com.br/tabulador-atendimento','content': 'Tabulador de Atendimento', 'text': 'Um tabulador de atendimento para ajudar a operacionalizar as suas vendas, as estratégias de relacionamento com o cliente e a inteligência do seu negócio.', 'servers': []},
					'5': {'id': 5,'status': false,'img': "/img/elements/mensurar.svg",'title': 'Mensurar', 'href': 'https://www.callflex.com.br/extrator-relatorios-personalizados','content': 'Extrator de Relatórios Personálizados', 'text': 'Um novo jeito de construir e extrair relatórios, de forma fácil e rápida. Conecte diversas fontes de dados com linguagens distintas. Mais controle da sua operação, otimizando o seu dia.','servers': []},
				};

				let services = res.data.data.services;

				if( services.length ){
					services.forEach(service => {
						if( 'undefined' !== typeof service.products ){
							if( service.products.length ){
								service.products.forEach(products => {
									if( 'undefined' !== accessProducts[ products.product_id ] ){
										accessProducts[ products.product_id ].status = true;
										products.servers.forEach(element => {
											accessProducts[ products.product_id ].servers.push( element ) ;	
										});
									}
								});
							}
						}
					});
				}

				for( let x in accessProducts ){
					const element = accessProducts[x];
					if( element.status === false ) {
						setAccessNo( true );
						break;
					}
				}

				setAccess( accessProducts )
			})
		}
		loadAccess()
	}, []);

	if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/logout';
		return false;
	}
	
	return (
		<React.Fragment>
			<div className="container">
				<div className="row">
					<div className="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
						<div className="ui-block">
							<div className="ui-block-title">
								<div className="logo-servicos">
									<a href="https://www.callflex.com.br/" target="_blank"><img src="/img/elements/logo.svg" className="white" alt="Callflex"/>
									<img src="/img/elements/logo_dark.svg" className="dark" alt="Callflex"/></a>
									<h5>Acesse seus clientes e mãos à obra</h5>
								</div>

							</div>
							<ul className="widget w-blog-posts w-products-access">
								{Object.keys( access ).map( x => (
									<Products  key={x} e={access[x]} type='ok'/>
								))}

								{
									( accessNo ? ( 
										<div className="ui-block-title no">
											<div className="logo-servicos-2">
												<h4>Conheça mais produtos</h4> 
												<h5>Obtenha + facilidade para<br/>o seu negócio</h5> 
											</div>
										</div>
									) : '' )
								}

								{Object.keys( access ).map( x => (
									<Products  key={x} e={access[x]} type='no'/>
								))}
							</ul>
						</div>
					</div>

					<div className="col col-xl-6 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
						{posts.map( post => (
							<Posts  key={post.id} post={post}/>
						))}
					</div>
					

					<div className="col col-xl-3 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
						<Itens />
					</div>
					
				</div>
			</div>
		</React.Fragment>
	);
}


export default Access;



 