import React, {Component} from 'react';
import { Cookies } from 'react-cookie';	
import MaskInput from 'react-maskinput';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../../utils/api';

import Menu from './Menu';

class FormAddress extends Component {
	YouToken = ''

	constructor() {
	  super();
	  this.state = { user: [] };

	  this.YouToken = this.checkCookie( 'YouToken' );
	  this.handlechangeDocument = this.handlechangeDocument.bind(this)
	  this.handleForm = this.handleForm.bind(this)
    }

	checkCookie( name ){
		const me = new Cookies();
		const You = me.get( name ) || '';
		if( You === '' ) return false;
		return You;
	}

	componentDidMount() {
		if( !this.YouToken ) return false;
 		const response =  api.get('/sso/user/address', {headers: {'Authorization': 'Bearer '+this.YouToken}  } );

		response.then( res => {

			if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.address ) window.location.href = '/logout';
			this.setState({ user: res.data.data.address[0] })
		})

	}
	componentWillUnmount() {
	}

	handlechangeDocument(e) {
		let me = this.state.user;
		this.setState( { user: me })
	}


	handleForm( e ){
		const MySwal = withReactContent(Swal)
		MySwal.fire({
			title: <p>Atenção!</p>,
			icon: 'warning',
			html: 'Tem certeza que deseja salvar as alterações?',
			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonColor: '#0f8084',
			cancelButtonColor: '#d33',
			confirmButtonText:	'<i class="fa fa-thumbs-up"></i> Sim',
			cancelButtonText: '<i class="fa fa-thumbs-down"></i> Não',
			onOpen: () => {
			  
			}
		}).then((action) => {
			if( !action.value ) return false;
			window.preloader( true );
			const user = this.state.user

			api.post('/sso/user/address', user, {headers: {'Authorization': 'Bearer '+this.YouToken}  } )
			.then( (response) => {
				if(  'undefined' === typeof response.data.data || 'undefined' === typeof response.data.data.affected_rows ) window.location.href = '/logout';
				window.preloader( false );
				MySwal.fire({
					icon: ( response.status === 200 && response.data.status === 'success' ? 'success' : 'error' ),
					title: ( response.status === 200 && response.data.status === 'success' ? 'Salvo com sucesso!' : 'Falha ao salvar!' ),
					showConfirmButton: false,
					timer: 1500
				})
			})
		})
		e.preventDefault();
    }
	
	onBlurZipCode( zipCode ){
		window.preloader( true );
		const response =  api.get('/sso/cep/'+zipCode, {headers: {'Authorization': 'Bearer '+this.checkCookie( 'YouToken' )}  } );

		response.then( res => {

			if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.cep ) {
				window.preloader( false );
				return false;
			}
			const cep = res.data.data.cep;
			this.setUser( 'address', cep.address );
			this.setUser( 'neighborhood', cep.neighborhood );
			this.setUser( 'city', cep.city );
			this.setUser( 'uf', cep.uf );

			window.document.querySelector('.selectpicker').dispatchEvent(new Event('change'));
			window.preloader( false );
		})

	}

	setUser( col, value ) {
		let me = this.state.user;

		me[ col ] = value;
		this.setState( { user: me })
	}

  
	render() {
		return (
			<React.Fragment>
				<div className="container cur" time={new Date().getTime()}>
					<div className="row">
						<div className="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
							<div className="ui-block">
								<div className="ui-block-title">
									<h6 className="title">Endereço</h6>
								</div>
								<div className="ui-block-content">
									<form>
										<div className="row">

											<div className="col col-lg-4 col-md-4 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">CEP</label>
                                                    <MaskInput className="form-control"  alwaysShowMask maskChar="_" mask="00000-000" size={8} value={this.state.user.zip_code} onChange={e => this.setUser( 'zip_code', e.target.value )} onBlur={e => this.onBlurZipCode( e.target.value )}/>
													<span className="material-input" />
												</div>
											</div>

											<div className="col col-lg-8 col-md-8 col-sm-12 col-12">
												<div className="form-group label-floating">
													<label className="control-label">Logradouro</label>
													<input className="form-control" maxLength="80" type="text" defaultValue={this.state.user.address} onChange={e => this.setUser( 'address', e.target.value )}/>
													<span className="material-input" />
												</div>
											</div>

                                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div className="form-group label-floating">
                                                    <label className="control-label">Bairro</label>
                                                    <input className="form-control" maxLength="60" type="text" defaultValue={this.state.user.neighborhood} onChange={e => this.setUser( 'neighborhood', e.target.value )}/>
                                                    <span className="material-input" />
                                                </div>
                                            </div>

                                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div className="form-group label-floating">
                                                    <label className="control-label">Cidade</label>
                                                    <input className="form-control" maxLength="60" type="text" defaultValue={this.state.user.city} onChange={e => this.setUser( 'city', e.target.value )}/>
                                                    <span className="material-input" />
                                                </div>
                                            </div>

                                            <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                                                <div className="form-group label-floating">
                                                    <label className="control-label">Estado</label>
                                                    <select className="selectpicker form-control" value={this.state.user.uf} onChange={e => this.setUser( 'uf', e.target.value )}>
                                                        <option disabled="" value="">Selecione</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="DF">Distrito Federal</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RN">Rio Grande do Norte</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="RO">Rondônia</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                                                <div className="form-group label-floating">
                                                    <label className="control-label">Número</label>
                                                    <input className="form-control" maxLength="6" type="number" defaultValue={this.state.user.number} onChange={e => this.setUser( 'number', e.target.value )}/>
                                                    <span className="material-input" />
                                                </div>
                                            </div>

                                            <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                                                <div className="form-group label-floating">
                                                    <label className="control-label">Complemento</label>
                                                    <input className="form-control" maxLength="60" type="text" defaultValue={this.state.user.complement} onChange={e => this.setUser( 'complement', e.target.value )}/>
                                                    <span className="material-input" />
                                                </div>
                                            </div>

										</div>
										<div className="row">
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
											</div>
											<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
												<button className="btn btn-purple btn-lg full-width" onClick={this.handleForm}>Salvar</button>
											</div>
										</div>
									</form>					
								</div>
							</div>
						</div>
						<Menu />
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default FormAddress;
