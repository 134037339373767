import React from 'react';
import checkSession from '../../utils/checkSession';
import api from '../../utils/api';
import Corp from './Corp';

const Itens = (props) => {

	if (!checkSession() && window.location.pathname !== "/") {
		window.location.href = '/logout';
		return false;
	}


	const handeOpen = function () {
		var browserDetect = navigator.userAgent.toLowerCase();
		const response = api.get('/sso/access/youniversity', { headers: { 'Authorization': 'Bearer ' + checkSession() } });

		response.then(res => {
			if ('undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.code) window.location.href = '/logout';

			const code = res.data.data.code;
			let url = res.data.data.url;

			if (url === '') {
				let error = window.document.querySelector('.modal.show .modal-products-sub-title.error h3');
				error.innerHTML = 'Servidor indisponível no momento. Tente novamente mais tarde';
				return false;
			}

			if (url.substr(url.length - 1, 1) !== '/') url = url + '/';
			url = 'https://youniversity.callflex.com.br/';
			url = url + 'oauth/token/' + code;
			if (browserDetect.indexOf('safari') != -1) {
				if (browserDetect.indexOf('chrome') > -1) {
					window.open(url, '_blank');
				} else {
					window.open(url, '_self');
				}
			}
		})
	}

	const handeOpenCorp = function () {
		var browserDetect = navigator.userAgent.toLowerCase();
		const response = api.get('/sso/access/youniversity', { headers: { 'Authorization': 'Bearer ' + checkSession() } });

		response.then(res => {
			if ('undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.code) window.location.href = '/logout';

			const code = res.data.data.code;
			let url = res.data.data.url;

			if (url === '') {
				let error = window.document.querySelector('.modal.show .modal-products-sub-title.error h3');
				error.innerHTML = 'Servidor indisponível no momento. Tente novamente mais tarde';
				return false;
			}

			if (url.substr(url.length - 1, 1) !== '/') url = url + '/';
			url = 'https://youniversity-corp.callflex.com.br/';
			url = url + 'oauth/token/' + code;
			if (browserDetect.indexOf('safari') != -1) {
				if (browserDetect.indexOf('chrome') > -1) {
					window.open(url, '_blank');
				} else {
					window.open(url, '_self');
				}
			}
		})
	}

	const checkIsCorp = function(){
		return ( window.YouProfile.mail ? ( (window.YouProfile.mail.indexOf('@callflex.') > -1 || window.YouProfile.mail.indexOf('@2cx.') > -1 ||  window.YouProfile.mail.indexOf('@voxage.') > -1) ? ( window.YouProfile.mail.indexOf('arsystem') > 0 ? false : true ) : false ) : false )
	}

	return (
		<React.Fragment>
			<div className="ui-block">
				<ul className="widget w-blog-posts w-products-youniversity">
					{
						checkIsCorp() ? ( <Corp handeOpen={handeOpenCorp} img="/img/corp.png"/> ) : ( ``)
					}					
					<Corp handeOpen={handeOpen} img="/img/client.png"/>
					
					<li>
						<article className="hentry post">
							<div className="youniversity">
								<img src="/img/elements/banner-youniversity-1.png" alt="" />
							</div>
						</article>
					</li>
					<li>
						<article className="hentry post">
							<div className="youniversity" >
								<img src="/img/elements/banner-youniversity-2.png" alt="" />
							</div>
						</article>
					</li>
					<li>
						<article className="hentry post">
							<div className="youniversity" >
								<img src="/img/elements/banner-youniversity-3.png" alt="" />
							</div>
						</article>
					</li>
				</ul>
			</div>
		</React.Fragment>
	);
}


export default Itens;



