import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import {useParams} from "react-router-dom";


const LoginFormRegisterProduct = ( props ) => {


	const { key } = useParams();
	const JWT = parseJwt( key );
	
	function parseJwt (token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		window.history.pushState("CallfexYOU", "CallflexYOU",  window.location.href.substr(0, (window.location.href.length - window.location.pathname.length))+'/register');
	
		return JSON.parse(jsonPayload);
	};



	const [ name, setName ] = useState('');
	const [ mail, setMail ] = useState('');
	const [ document, setDocument ] = useState('');
	const [ birth, setBirth ] = useState('');
	const [ sex, setSex ] = useState('');
	const [ acepted, setAcepted ] = useState('');
	const [ alertMessage, setAlert ] = useState('');
	

	useEffect( () => {
		function add(){
			setDocument( JWT.key.d );
			setName( JWT.key.n );
			setBirth( new Date().toLocaleDateString() );
		}

		add();
	})

    async function handleSubmit( e ){
		e.preventDefault();

		if( name.length < 10 ){
			setAlert('Nome Inválido')
			return false;
		}
		
		if( !mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ){
			setAlert('E-mail Inválido')
			return false;
		}

		if( !checkCPF(document) ){
			setAlert('CPF Inválido')
			return false;
		}

		if( !acepted || acepted === '' ){
			setAlert('Para continuar é necessário aceitar os Termos e Condições')
			return false;
		}

		const response = await api.post('/sso/user/register', {
			key: key,
			name,
			mail,
			document,
			birth,
			sex,
			acepted
		} );

		if( 'undefined' === typeof response.data ){
			setAlert('Houve uma falha. Contate o suporte.')
			return false;
		}
		if( 'undefined' === typeof response.data.status ){
			setAlert('Houve uma falha. Contate o suporte.')
			return false;
		}

		if( response.data.status === 'success' ) {
			localStorage.setItem('@YouMail', btoa(mail));
			window.location.href = '/register';
		}
		setAlert( response.data.message )
		return false;
	}

	function checkCPF(cpf){
		cpf = cpf.replace(/[^\d]+/g,'');	
		if(cpf === '') return false;	
		if (cpf.length !== 11 || 
			cpf === "00000000000" || 
			cpf === "11111111111" || 
			cpf === "22222222222" || 
			cpf === "33333333333" || 
			cpf === "44444444444" || 
			cpf === "55555555555" || 
			cpf === "66666666666" || 
			cpf === "77777777777" || 
			cpf === "88888888888" || 
			cpf === "99999999999")
				return false;		

		let add = 0;	
		for (let i=0; i < 9; i ++)		
			add += parseInt(cpf.charAt(i)) * (10 - i);	
			let rev = 11 - (add % 11);	
			if (rev === 10 || rev === 11)		
				rev = 0;	
			if (rev !== parseInt(cpf.charAt(9)))		
				return false;		

		add = 0;	
		for (let i = 0; i < 10; i ++)		
			add += parseInt(cpf.charAt(i)) * (11 - i);	
		rev = 11 - (add % 11);	
		if (rev === 10 || rev === 11)	
			rev = 0;	
		if (rev !== parseInt(cpf.charAt(10)))
			return false;		
		return true;   
	}

	return (
		<React.Fragment>
			<div className="title h6">Registro no YOU</div>
			<form className="content register" onSubmit={handleSubmit}>
				<div className="row">
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">Nome Completo</label>
							<input className="form-control" placeholder="" type="text" value={name} onChange={e => setName(e.target.value) }/>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">E-mail</label>
							<input className="form-control" placeholder="" type="email"  value={mail} onChange={e => setMail(e.target.value) }/>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="form-group label-floating">
							<label className="control-label">CPF</label>
							<input className="form-control" placeholder="" type="text"  value={document} readOnly/>
						</div>
					</div>
					<div className="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<div className="form-group date-time-picker label-floating">
							<label className="control-label">Data de Nascimento</label>
							<input name="datetimepicker" value={birth} onChange={e => setBirth(e.target.value) }/>
							<span className="input-group-addon">
								<svg className="olymp-calendar-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-calendar-icon" /></svg>
							</span>
						</div>
					</div>
					<div className="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<div className="form-group label-floating is-select">
							<label className="control-label">Sexo</label>
							<select className="selectpicker form-control" value={sex} onChange={e => setSex(e.target.value) }>
								<option value="male">Masculino</option>
								<option value="female">Feminino</option>
							</select>
						</div>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<p className="alert">{alertMessage}</p>
					</div>
					<div className="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div className="remember">
							<div className="checkbox">
								<label>
								<input name="optionsCheckboxes" type="checkbox"  value={acepted} onChange={e => setAcepted(e.target.checked) }/>
									Eu aceito os <a href="/terms">termos e condições</a> deste site.
								</label>
							</div>
						</div>
						<a href="/#" className="btn btn-primary btn-lg full-width" onClick={handleSubmit}>Registrar!</a>
					</div>
				</div>
			</form>
		</React.Fragment>
	);
}


export default LoginFormRegisterProduct;
