import { Cookies } from 'react-cookie';

const checkCookie = ( name ) => {
    const me = new Cookies();
    const You = me.get( name ) || '';
    if( You === '' ) return false;
    return You;
}

const cookieYou = {
    set ( name, data ){
        const cookie = new Cookies();
        cookie.set(name, data,{
            secure: ( window.location.protocol === 'https' ? true : false ),
            httpOnly: ( window.location.protocol === 'https' ? true : false )
        });
        return true;
    },
    get( name ){
        const YouToken = checkCookie( name );
        return YouToken || '';
    }
}

export default cookieYou;