import React from 'react';
import checkSession from '../../utils/checkSession'
import api from '../../utils/api';

const MenuDetail = ( props ) => {

	let className = 'left-menu-title';
	
	if( 'undefined' !== typeof props.hide ){
		className = className + ' hide';
	}

	if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/logout';
		return false;
	}
	
    const handeOpen = function(  ){
        const response =  api.get('/sso/access/youniversity', {headers: {'Authorization': 'Bearer '+checkSession()}  } );

		response.then( res => {
			if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.code ) window.location.href = '/logout';
			
			const code = res.data.data.code;
			let url = res.data.data.url;

			if( url === '' ){
				let error = window.document.querySelector('.modal.show .modal-products-sub-title.error h3');
				error.innerHTML = 'Servidor indisponível no momento. Tente novamente mais tarde';
				return false;
			}

			if( url.substr( url.length-1,1 ) !== '/' ) url = url + '/';
			url = url + 'oauth/token/'+code;

			window.open(url, '_blank');
        })
        
    }

	return (
		<React.Fragment>
				<li>
					<a href="#0" className="js-sidebar-open">
						<img src="/img/menu/open-menu.svg" alt="CallflexYOU" className="ico-menu" data-toggle="tooltip" data-placement="right" data-original-title="ABRIR MENU" style={{display: 'block'}}/>
						<span className={className}>FECHAR MENU</span>
					</a>
				</li>
				<li>
					<a href="/home">
						<img src="/img/menu/home-run.svg" alt="CallflexYOU" className="ico-menu"/>
						<span className={className}>PRINCIPAL</span>
					</a>
				</li>
				<li>
					<a href="/towork">
						<img src="/img/elements/headphone_menu.svg" alt="CallflexYOU" className="ico-menu"/>
						<span className={className}>TRABALHAR</span>
					</a>
				</li>
				<li>
					<a href="#0" rel="noopener noreferrer" onClick={handeOpen}>
						<img src="/img/menu/student.svg" alt="CallflexYOU" className="ico-menu"/>
						<span className={className}>YOUNIVERSITY</span>
					</a>
				</li>
		</React.Fragment>
	);
}


export default MenuDetail;
