import React from 'react';

import Header from '../components/login/Header';

const Terms = ( props ) => {

    return (
        <React.Fragment>
            <div className="stunning-header bg-primary-opacity">
                <Header/>
                    <div className="header-spacer--standard" />
                        <div className="stunning-header-content">
                        <h1 className="stunning-header-title">Termos</h1>
                    </div>
                    <div className="content-bg-wrap stunning-header-bg1" />
            </div>
                <section className="mb60 terms">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xl-8 m-auto col-lg-10 col-md-12 col-sm-12 col-12">
                                <div id="accordion" role="tablist" aria-multiselectable="true" className="accordion-faqs">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                        <p>Bem-vindo ao Youniversity! Ao utilizar nossos serviços, você concorda com os seguintes termos. Por favor, leia-os atentamente antes de acessar ou utilizar o site. </p>

                                        <h3>Aceitação dos Termos de Uso:  </h3>

                                        <p>Ao acessar ou utilizar o Youniversity, você concorda em cumprir estes Termos de Uso e todas as leis e regulamentos aplicáveis. Se você não concordar com estes termos, por favor, não utilize este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis. </p>

                                        

                                        <h3>Uso Permitido: </h3>

                                        <p>Este site é destinado a fornecer treinamento e informações sobre produtos 2CX (Callflex + Voxage). Você concorda em usar o site apenas para fins legais e de forma que não viole os direitos de terceiros. </p>

                                        

                                        <h3>Conta do Usuário: </h3>

                                        <p>Alguns recursos podem exigir que você crie uma conta de usuário. Você é responsável por manter a confidencialidade de suas credenciais de conta e por todas as atividades que ocorrem em sua conta. </p>

                                        

                                        <h3>Propriedade Intelectual: </h3>

                                        <p>Todo o conteúdo presente neste site, incluindo, mas não se limitando a texto, gráficos, logotipos, ícones de botões, imagens, clipes de áudio, downloads digitais, compilações de dados e software, é propriedade da 2CX (Callflex + Voxage) ou de seus fornecedores de conteúdo e está protegido pelas leis de direitos autorais e outras leis de propriedade intelectual. </p>

                                        

                                        <h3>Limitações de Responsabilidade: </h3>

                                        <p>O conteúdo deste site é fornecido apenas para fins informativos. Nós não garantimos a precisão, integridade ou atualidade das informações neste site. O uso das informações obtidas neste site é por sua própria conta e risco. Não nos responsabilizamos por quaisquer danos diretos, indiretos, consequenciais ou incidentais resultantes do uso ou da incapacidade de usar este site. </p>

                                        <h3>Modificações:  </h3>

                                        <p>Reservamos o direito de modificar estes Termos de Uso a qualquer momento, e tais modificações serão efetivas imediatamente após serem publicadas neste site. Recomendamos que você revise periodicamente estes Termos de Uso para estar ciente de quaisquer alterações. </p>

                                        <h3>Lei Aplicável:  </h3>

                                        <p>Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil, sem consideração a seus conflitos de disposições legais. </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </React.Fragment>
    );
}


export default Terms;
