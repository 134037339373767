import React from 'react';
import Contact from './Contact';

const Footer = ( props ) => {

	return (
		<React.Fragment>
            {
				/*
				<a className="back-to-top" href="#\">
					<img src="/svg-icons/back-to-top.svg" alt="arrow" className="back-icon" />
				</a>
				*/
			}

			<Contact />
		</React.Fragment>
	);
}


export default Footer;

