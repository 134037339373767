import React from 'react';
import checkSession from '../../utils/checkSession';
import api from '../../utils/api';

const Products = ( props ) => {
    const { e, type } = props;

    if( !checkSession() && window.location.pathname !== "/" ) {
		window.location.href = '/logout';
		return false;
    }
    
    function uuidv4() {
        const url = URL.createObjectURL(new Blob())
        const [id] = url.toString().split('/').reverse()
        URL.revokeObjectURL(url)
        return id
    }
    
    const handeOpen = function( uuid ){
        const servers = window.document.querySelector('div[id="'+uuid+'"] #server');
        const selected = servers.options[servers.selectedIndex].value;

        let url = '';

        e.servers.forEach(element => {
            if( element.server_hash === selected ){
                url = element.uri_application;
            }
        });

        if( url === '' ){
            let error = window.document.querySelector('.modal.show .modal-products-sub-title.error h3');
            error.innerHTML = 'Servidor indisponível no momento. Tente novamente mais tarde';
            return false;
        }
        
        const response =  api.get('/sso/access/'+selected, {headers: {'Authorization': 'Bearer '+checkSession()}  } );

		response.then( res => {

			if(  'undefined' === typeof res.data.data || 'undefined' === typeof res.data.data.code ) window.location.href = '/logout';
			const code = res.data.data.code;
			if( url.substr( url.length-1,1 ) !== '/' ) url = url + '/';
			if( url.indexOf( 'token/') > -1 ) {
				url = url + ''+code;
			}else{
				url = url + '?token='+code;
            }
            var a = document.createElement('a');
            var linkText = document.createTextNode("my title text");
            a.appendChild(linkText);
            a.title = "Access";
            a.href = url;
            a.target = "_blank";

            a.click();
        })
        
    }

    const uuid = uuidv4();
    const uuidModal = '#'+uuid;
    if( type === 'ok' && e.status === true ){
        return (
            <React.Fragment>
                <li key={e.id}>
                    <article className="hentry post" data-toggle="modal" data-target={uuidModal}>
                        <div className="product">
                            <img src={e.img} alt={e.title} className="product-active"/>
                            <h3>{e.title}</h3>
                            <p>{e.content}</p>
                        </div>
                        <div className="access">Acessar</div>
                    </article>
                        <div className="modal fade" id={uuid} tabIndex={-1} role="dialog" aria-labelledby={uuid} aria-hidden="false">
                            <div className="modal-dialog window-popup main-popup-search" role="document">
                                <div className="modal-content">
                                    <a href="/#" className="close icon-close" data-dismiss="modal" aria-label="Close">
                                        <svg className="olymp-close-icon"><use xlinkHref="svg-icons/sprites/icons.svg#olymp-close-icon" /></svg>
                                    </a>
                                    <div className="modal-body">
                                        <div className="modal-products-title"><h2>{e.title}</h2></div>

                                        <div className="modal-products-sub-title"><h3>Indique qual servidor você quer acessar</h3></div>

                                        <div className="modal-products-servers">
                                            <div className="form-group label-floating is-select">
                                                <label className="control-label"></label>
                                                <select className="form-control" id="server">
                                                    {e.servers.map( server => (
                                                        <option key={server.server_hash} value={server.server_hash}>{server.server_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="modal-products-servers-open" onClick={(e) => { return handeOpen( uuid ) }}>
                                                Acessar
                                            </div>
                                        </div>

                                        <div className="modal-products-sub-title error"><h3>&nbsp;</h3></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </li>	
            </React.Fragment>
        );
    }else if( type === 'no' && e.status === false ){
        return (
            <React.Fragment>
                <li key={e.id}>
                    <article className="hentry post no">                        
                        <div className="product">
                            <img src="/img/elements/btn-conheca-mais.png" className="conheca-mais"/>
                            <img src={e.img} alt={e.title}/>
                            <p className="title-p">{e.title}</p>
                            <p>{e.content}</p>
                            <a href={e.href} target="_blank" rel="noopener noreferrer"><div>Conhecer</div></a>
                        </div>
                    </article>
                </li>	
            </React.Fragment>
        );
    }else{
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}


export default Products;