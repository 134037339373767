import React, { useState, useEffect } from 'react';
import checkSession from '../utils/checkSession'

import Header from '../components/logged/Header';
import Pictures from '../components/profile/Pictures';
import MenuResponsive from '../components/profile/MenuResponsive';
import FormPersonProfessional from '../components/profile/FormPersonProfessional';
import Footer from './Footer';


const Profiles = ( props ) => {

	const [ YouProfile, setYouProfile ] = useState([]);

	useEffect( () => { 
		async function loadDevs(){
			setYouProfile( checkSession( 'YouProfile' ) )
		}

		loadDevs()
	}, []);
	


	return (
		<React.Fragment>
			<MenuResponsive />
			<Header YouProfile={YouProfile} title="Perfil"/>
			<Pictures YouProfile={YouProfile} />
			<FormPersonProfessional />
			<Footer />
		</React.Fragment>
	);
}


export default Profiles;
